import React from 'react'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'


function Favicon() {
  return (
    <Helmet>
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
  )
}

export default Favicon
