import React from 'react'
import Helmet from 'react-helmet'

function Fonts() {
  return (
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Fira+Sans:300|Josefin+Sans:400&amp;display=swap" rel="stylesheet" />
    </Helmet>
  )
}
export default Fonts
